import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class VerificacionKnox extends Service {
    /**
     * * @param  {String} creditApplicationId
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    send(creditApplicationId) {

        return this._requestWrapper(LandingV4Instance({
            method: 'POST',
            url: '/verifications/knox',
            params: {
                creditApplicationId
            }

        }))
    }

}
