import { object } from 'prop-types'
import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 * @param  {String} landingApplicationId
 */
export class PrescoreService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._signature = null
  }

  setSignature(signature) {
    this._signature = signature
  }

  get data() {
    // en este caso no se envia un object se envia como multioart/form-data
    const formData = new FormData();
    // en la linea 24 se agrega al multipart el file del signature, el primer parametro es la key del service y el segundo su valor
    formData.append('Signature', this._signature)
    // en la linea 26 se agrega al multipart el credit aplicationId, el primer parametro es la key del service y el segundo su valor
    formData.append('CreditApplicationId', this._creditApplicationId)
    // retornamos ala peticion el multipart creado
    return formData
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/prescore/signature',
      data: self.data,
      timeout: 180000,
    }))
  }

  /**
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  sendAfi() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/verifications/legacy-signature',
      data: self.data,
      timeout: 180000,
    }))
  }

  /**
  * @param  {String} KbaSessionId 
  * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
  */
  prescoreKba(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/prescore/kba',
      headers: {
        'accept-language': 'es'
      },
      params: {
        creditApplicationId
      }
    }))
  }

  /**
 * @param  {String} KbaSessionId 
 * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
 */
  prescoreSinKba(creditApplicationId) {
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: '/prescore',
      params: {
        creditApplicationId
      }
    }))
  }

}


