import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class VerificacionTelefonica extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
        this._mobileNumber = ''
    }

    get data() {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            mobileNumber: `${this._mobileNumber}`,
        }
    }
    /**
     * @param  {String} mobileNumber
     */
    setMobileNumber(mobileNumber) {
        this._mobileNumber = `${mobileNumber}`
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    correctionsMobile() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: '/corrections/mobilenumber',
            data: self.data
        }))
    }

}
