import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class ScoreService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._maritalStatusId = ''
    this._occupationId = ''
    this._incomeSourceId = ''
    this._income = 0
    this._expenses = 0
    this._dependents = 0
    this._homeSinceTotalMonths = 0
    this._jobSinceTotalMonths = 0
    this._workPhoneNumber = ''
    this._alternatePhoneNumber = ''
    this._alternatePhoneNumber = false
    this._checkIncome = false    
  }

  get data() {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      maritalStatusId: `${this._maritalStatusId}`,
      occupationId: `${this._occupationId}`,
      incomeSourceId: this._incomeSourceId === undefined ? undefined : `${this._incomeSourceId}`,
      income: `${this._income}`,
      expenses: `${this._expenses}`,
      dependents: `${this._dependents}`,
      homeSinceTotalMonths: `${this._homeSinceTotalMonths}`,
      jobSinceTotalMonths: `${this._jobSinceTotalMonths}`,
      workPhoneNumber: `${this._workPhoneNumber}`,
      alternatePhoneNumber: `${this._alternatePhoneNumber}`,
      checkIncome: this._checkIncome,
    }
  }

  get dataPensionados() {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
      maritalStatusId: `${this._maritalStatusId}`,
      income: `${this._income}`,
      expenses: `${this._expenses}`,
      dependents: `${this._dependents}`,
      homeSinceTotalMonths: `${this._homeSinceTotalMonths}`,
    }
  }

  /**
 * @param  {Boolean} checkIncome
 */
   setCheckIncome(checkIncome) {
    this._checkIncome = checkIncome
  }

  /**
   * @param  {String} maritalStatusId
   */
  setMaritalStatusId(maritalStatusId) {
    this._maritalStatusId = `${maritalStatusId}`
  }

  /**
   * @param  {String} occupationId
   */
  setOccupationId(occupationId) {
    this._occupationId = `${occupationId}`
  }


  /**
   * @param  {String} incomeSourceId
   */
  setIncomeSourceId(incomeSourceId) {
    this._incomeSourceId = incomeSourceId === undefined ? undefined : `${incomeSourceId}`
  }

  /**
   * @param  {Number} income
   */
  setIncome(income) {
    this._income = income
  }

  /**
   * @param  {Number} expenses
   */
  setExpenses(expenses) {
    this._expenses = expenses
  }

  /**
   * @param  {Number} homeSinceTotalMonths
   */
  setHomeSinceTotalMonths(homeSinceTotalMonths) {
    this._homeSinceTotalMonths = homeSinceTotalMonths
  }

  /**
   * @param  {Number} dependents
   */
  setDependents(dependents) {
    this._dependents = dependents
  }

  /**
   * @param  {Number} jobSinceTotalMonths
   */
  setJobSinceTotalMonths(jobSinceTotalMonths) {
    this._jobSinceTotalMonths = `${jobSinceTotalMonths}`
  }

  /**
   * @param  {Number} workPhoneNumber
   */
  setWorkPhoneNumber(workPhoneNumber) {
    this._workPhoneNumber = `${workPhoneNumber}`
  }

  /**
 * @param  {Number} alternatePhoneNumber
 */
  setAlternatePhoneNumber(alternatePhoneNumber) {
    this._alternatePhoneNumber = `${alternatePhoneNumber}`
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/score',
      data: self.data
    }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  sendPensionados() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/score/pension',
      data: self.dataPensionados
    }))
  }
}
