import Service from '../Service'
import { LandingV4Instance } from './Instance'

 /**
 * @typedef {Object} CustomerData
 * @property {String} landingApplicationId
 * @property {String} names
 * @property {String} lastName
 * @property {String} mothersName
 * @property {String} mobileNumber
 * @property {String} relacion 
 */

 /**
 * @class
 * @param  {String} landingApplicationId
 */

 export class Referencia extends Service {
     constructor(landingApplicationId) {
         super()
         this._landingApplicationId = `${landingApplicationId}`
         this._names = ''
         this._lastName = ''
         this._mothersName = ''
         this._mobileNumber = ''
         this._relacion = '' 
         this._arrayData = []                 
     }
 
 /**
   * @param {String} names   
   */
 setNames(names) {  
     this._names = `${names}`
 }

 /**
   * @param {String} lastName   
   */
 setLastName(lastName) {  
     this._lastName = `${lastName}`
 }

 /**
   * @param  {String} mothersName   
   */
 setMothersName(mothersName) {  
    this._mothersName = `${mothersName}`
 }


 /**
   * @param  {String} mobileNumber
   */
  setMobileNumber(mobileNumber) {
    this._mobileNumber = `${mobileNumber}` 
  }
  
 /**
   * @param  {String} relacion
   */
  setRelacion(relacion) {
    this._relacion = `${relacion}` 
  }

  setArrayData(referencia) {
    this._arrayData = [...this._arrayData, `${referencia}`]
  }
  
  /**
   * @returns {CustomerData}
   */
   get data () {
    return {              
        creditApplicationId: `${this._landingApplicationId}`,
        references: `${this._arrayData}`
      }
   }


  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send (path, references) {
    const self = this
    return this._requestWrapper(LandingV4Instance({
    method: 'PUT',
    url: path,
    data: {
      "creditApplicationId": `${this._landingApplicationId}`,
      "references": references
    }
  }))
  }
 }


