import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class Encuesta extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
        this._idEncuesta = 0
        this._idPregunta = 0
        this._respuesta = ''
    }

    get data() {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            idEncuesta: `${this._idEncuesta}`,
            answers: [
                {
                    idPregunta: `${this._idPregunta}`,
                    respuesta: `${this._respuesta}`
                }
            ]
        }
    }


    /**
     * @param  {Number} idEncuesta
     */
    setIdEncuesta(idEncuesta) {
        this._idEncuesta = idEncuesta + 0
    }

    /**
     * @param  {Number} idPregunta
     */
    setIdPregunta(idPregunta) {
        this._idPregunta = idPregunta + 0
    }

    /**
     * @param  {String} respuesta
     */
    setRespuesta(respuesta) {
        this._respuesta = `${respuesta}`
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    send() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: '/surveys/nps',
            data: self.data
        }))
    }
}
