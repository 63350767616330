import Service from '../Service'
import { LandingV4Instance } from './Instance'
 
 /**
 * @class
 * @param  {String} creditApplicationId 
 */

 export class IncomeIngresos extends Service {
     constructor(creditApplicationId) {
         super()
         this._creditApplicationId = creditApplicationId
         this._file = ''
         this._employerName = ''
     }
  
  setFile(file) {
    this._file = file
  }      

  setEmployer(employer) {
    this._employerName = employer
  }
 
  
   get data () {
      // en este caso no se envia un object se envia como multioart/form-data
      const formData = new FormData();
      // se agrega al multipart el file del signature, el primer parametro es la key del service y el segundo su valor
          formData.append('File', this._file);       
          formData.append('CreditApplicationId',this._creditApplicationId);             

      return formData
   }

   get sendData () {    
      return {
        creditApplicationId: `${this._creditApplicationId}`,
        employerName: `${this._employerName}`,        
      }    
   }


  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  buttonSend() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
    method: 'PUT',
    url: `/income`,
    data: self.sendData  
  }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
   fileSend() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/income',
      data: self.data
    }))
  }


 }


