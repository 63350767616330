import Service from '../Service'
import { LandingV4Instance } from './Instance'
import { LandingV2Instance } from './InstanceV2'

/**
 * @class
 */
export class Firma extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
        this._code = ''
        this._confirmation = ''
    }

    get data() {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            code: `${this._code}`,
        }
    }

    get dataNip () {
      const formData = new FormData();
          formData.append('Code',this._code)
          formData.append('Confirmation',this._confirmation)
          formData.append('CreditApplicationId',this._creditApplicationId)
      return formData
    }

    /**
     * @param  {String} code
     */
    setCode(code) {
        this._code = `${code}`
    }
    /**
     * @param  {String} confirmation
     */
     setConfirmation(confirmation) {
        this._confirmation = `${confirmation}`
    }    

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
     prescoreNip() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: '/prescore/nip',
            // data: self.dataNip
            params: {
                creditApplicationId: `${this._creditApplicationId}`
            }
        }))
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
     signatureV2() {
        const self = this
        return this._requestWrapper(LandingV2Instance({
            method: 'POST',
            url: '/signature',
            data: {
                creditApplicationId: `${this._creditApplicationId}`,
                code: `${this._code}`,
                confirmation: `${this._confirmation}`
              }
        }))
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
     verificationsPhone() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'POST',
            url: '/verifications/phone',
            data: {
                creditApplicationId: `${this._creditApplicationId}`,
                code: `${this._code}`,
              }
        }))
    }    

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    verifySignature() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'POST',
            url: '/signature',
            data: self.data
        }))
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    reSendSignature(creditApplicationId) {
        // const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: '/signature/resend',
            params: {
                creditApplicationId
            }
        }))
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    abort(creditApplicationId) {
        // const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: '/signature/abort',
            params: {
                creditApplicationId
            }
        }))
    }    

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
     prescoreNipResend(creditApplicationId) {
        // const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: `/prescore/nip/resend?creditApplicationId=${creditApplicationId}`,
            // params: {
            //     creditApplicationId
            // }
        }))
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
     afiPutSignature() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: `/signature/afi`,
            params: {
                creditApplicationId: self._creditApplicationId
            }
        }))
    }

    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
     afiGetSignature() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'GET',
            url: `/signature/afi/${self._creditApplicationId}`,
        }))
    }
}
